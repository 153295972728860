import LazyDisp from './lazydisp';
import LazyShow from './lazyshow';
// import FirstViewEndObserver from "../extended_class/FirstViewEndObserver";

const [d] = [document];

export default class COMMON {
  constructor(opt) {
    // settings
    this.opt = Object.assign({
      lazydisp: '[data-lazydisp]',
      lazyshow: '[data-lazyshow]',
    }, opt);
	}
  init()
  {
    // イベント一覧
    d.addEventListener('Lazydisp.beforeInit', () => false);
    d.addEventListener('Lazyshow.beforeInit', () => false);

		// イベント発火
    d.dispatchEvent(new CustomEvent('common.beforeInit', { detail: this }));
    this.LazyDisp = new LazyDisp(this.opt.lazydisp);
    this.LazyShow = new LazyShow(this.opt.lazyshow);
    // console.log(this.LazyShow);
	}

  domReady() {
    // console.log(`test`);
  }
}