import ScrollObserver from "./scrollObserver";

const [d] = [document];

export default class LazyShow extends ScrollObserver {
  constructor(selector, opt) {
    opt = Object.assign({
      // デフォルトオプションの設定
      // root: null, // ビューポートをルート要素とする
      // rootMargin: "20% 0px -20%", // ビューポートの中心を判定基準にする
      // threshold: 0 // 閾値は0
    }, opt);
    super(opt); //親クラスのコンストラクタを呼び出す
    this.init(selector); //初期化
  }

  init(selector) {
    d.dispatchEvent(new CustomEvent('Lazyshow.beforeInit', { detail: this }));
    d.querySelectorAll(selector).forEach(elm => {
      setTimeout(() => elm.classList.remove('lazyReady'), 200);
    });
    this.observeElement(selector);
  }

  intersectIn(entry, object) {
    const target = entry.target;
    target.classList.add('lazyshow');
    if (target.dataset.lazyAddclass != undefined) {
      target.classList.add(target.dataset.lazyAddclass);
    }
    object.unobserve(target);
  }

}